<template>
    <div>
        <div class="modal1" v-if="showData == 'modal1'">
            <a-modal v-model="modal1Visible" :footer="null" :title="null" :closable="false" centered :keyboard="false"
                :maskClosable="false" width="600">
                <div v-if="Selection">
                    <h2 class="modal1-title">创建/加入一个企业</h2>
                    <p class="modal1-content">您必须创建或者加入一个企业才能查看数据、进行操作。</p>
                    <div class="modal1-box">
                        <div class="creatCom" @click="createCom">
                            <img :src="require('@/assets/img/creatCom.png')" alt="" srcset="">
                            <p>创建新的企业</p>
                        </div>
                        <div class="joinCom" @click="joinCom">
                            <img :src="require('@/assets/img/joinCom.png')" alt="" srcset="">
                            <p>加入一个企业</p>
                        </div>
                    </div>
                </div>
                <div v-if="!Selection && createCompany" style="width: 4.75rem;">
                    <h2 class="modal1-title">创建新的企业</h2>
                    <p class="modal1-content">请填写企业真实信息以便项目维护</p>
                    <div>
                        <a-form :form="form" :label-col="{ span: 5 }" @submit="handleSubmit">
                            <a-form-item>
                                <a-input block v-decorator="['note', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                                    placeholder="请输入企业名称" />
                            </a-form-item>
                            <a-form-item>

                                <!-- <a-select block v-decorator="[
                                        'gender',
                                        { rules: [{ required: true, message: '请选择所在地区' }] },
                                    ]" placeholder="请选择所在地区" @change="handleSelectChange">
                                    <a-select-option value="male">
                                        male
                                    </a-select-option>
                                    <a-select-option value="female">
                                        female
                                    </a-select-option>
                                </a-select> -->

                                <a-cascader :field-names="{ label: 'name', value: 'code', children: 'items' }"
                                    :options="options" v-decorator="[
                                            'area',
                                            { rules: [{ required: true, message: '请选择所在地区' }] },
                                        ]" placeholder="请选择所在地区" @change="onChange" />

                            </a-form-item>
                            <a-form-item>
                                <div class="flex-end">
                                    <a-button @click="cancelCreate">
                                        取消
                                    </a-button>
                                    <a-button style="margin-left: 0.2rem;" type="primary" html-type="submit">
                                        确定
                                    </a-button>
                                </div>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
                <div v-if="!Selection && !createCompany" style="width: 4.75rem;">
                    <h2 class="modal1-title">加入企业</h2>
                    <p class="modal1-content">填写企业名称之后等待管理员审核</p>
                    <div>
                        <a-form :form="joinForm" :label-col="{ span: 5 }" @submit="handleJoinSubmit">
                            <a-form-item>
                                <a-input block v-decorator="['note', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                                    placeholder="请输入企业名称" />
                            </a-form-item>
                            <a-form-item>
                                <div class="flex-end">
                                    <a-button @click="cancelJoin">
                                        取消
                                    </a-button>
                                    <a-button style="margin-left: 0.2rem;" type="primary" html-type="submit">
                                        确定
                                    </a-button>
                                </div>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>

            </a-modal>
        </div>
        <div class="modal2" v-if="showData == 'modal2'">
            <a-modal v-model="modal2Visible" :footer="null" :title="null" :closable="false" centered :keyboard="false"
                :maskClosable="false" width="600">
                <div class="modal2-box">
                    <img :src="require('@/assets/img/waitPass.png')" alt="" srcset="">
                    <p>等待管理员审核通过...</p>
                </div>
            </a-modal>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            modal1Visible: true,
            modal2Visible: true,
            Selection: true,
            createCompany: false,
            showData: '',
            // 创建企业form
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            // 加入企业form
            joinForm: this.$form.createForm(this, { name: 'coordinated' }),
            options: [
                {
                    code: '四川省',
                    name: '四川省',
                    items: [
                        {
                            code: '成都市',
                            name: '成都市',
                            items: [
                                {
                                    code: '金牛区',
                                    name: '金牛区',
                                }, {
                                    code: '青羊区',
                                    name: '青羊区',
                                }, {
                                    code: '武侯区',
                                    name: '武侯区',
                                }
                            ],
                        },
                    ],
                },
                {
                    code: '江苏',
                    name: '江苏',
                    items: [
                        {
                            code: '南京',
                            name: '南京',
                            items: [
                                {
                                    code: '中华门',
                                    name: '中华门',
                                },
                            ],
                        },
                    ],
                },
            ]
        };
    },
    created() {
        this.showData = 'modal1';//首次进入空状态
        // this.showData = 'modal2';//首次进入待通过
    },
    components: {

    },
    mounted() {
        ;
    },
    methods: {
        // 选择切换控制
        createCom() {
            this.Selection = false;
            this.createCompany = true;
        },
        joinCom() {
            this.Selection = false;
            this.createCompany = false;
        },
        // 创建企业form
        cancelCreate() {
            this.Selection = true;
            this.createCompany = false;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                }
            });
        },
        handleSelectChange(value) {
            console.log(value);
            this.form.setFieldsValue({
                note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
            });
        },
        // 加入企业form
        cancelJoin() {
            this.Selection = true;
            this.createCompany = false;
        },
        handleJoinSubmit(e) {
            e.preventDefault();
            this.joinForm.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                }
            });
        },
        handleSelectChange(value) {
            console.log(value);
            this.joinForm.setFieldsValue({
                note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
            });
        },
        onChange(value) {
            console.log(value);
        },
    },
}
</script>

<style lang="scss" scoped>
.modal1-title {
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #333333;
}

.modal1-content {
    height: 0.21rem;
    font-size: 0.15rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #818C98;
}

.modal1-box {
    display: flex;
}

.creatCom,
.joinCom {
    width: 2.38rem;
    height: 2.14rem;
    cursor: pointer;
    padding: 0.31rem 0.39rem 0.34rem;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
        border: 1px solid #4689F6;

        img {}

        p {
            color: #4689F6;
        }
    }

    img {
        width: 1.6rem;
        height: 1.15rem;
    }

    p {
        font-size: 0.18rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #818C98;
        text-align: center;
    }
}

.modal2-box {
    width: 5rem;
    height: 3.8rem;
    // cursor: pointer;
    padding: 0.31rem 0.39rem 0.34rem;
    box-sizing: border-box;
    border-radius: 4px;

    // &:hover {
    //     border: 1px solid #4689F6;

    //     img {}

    //     p {
    //         color: #4689F6;
    //     }
    // }

    img {
        width: 4.85rem;
        height: 3rem;
    }

    p {
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #818C98;
        text-align: center;
    }
}

::v-deep .ant-modal-content {
    border-radius: 10px;
}

::v-deep .ant-cascader-picker .ant-cascader-input {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
}
</style>